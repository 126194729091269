import React, { useState, useEffect } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom';
import TopHeader from '../Components/TopHeader';
import Prince from '../Components/Prince';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ProjectEdit = () => {

  const { empid } = useParams();
  const navigate = useNavigate();
  const initialFormData = {
    locationId: '',
    projectName: '',
    status: '',
    banner: '',
    details: '',
    noOfPlot: '',
    noOfShop: '',
    noOfFarmHouse: '',

    areaAllocateToProject: '',
    areaAvailableForProject: '',
    size: '',

  };

  const [formData, setFormData] = useState(initialFormData);
  const [profilePic, setProfilePic] = useState(null);
  const [location, setLocation] = useState([]);
  const [status, setStatus] = useState([]);


  const Token = localStorage.getItem("Token");
  const apiUrl = process.env.REACT_APP_URL;

  useEffect(() => {
    const fetchUsers = async () => {
      if (!formData.locationId) return;

      try {
        const url = `${apiUrl}/location/getLocation/${formData.locationId}`;
        let result = await fetch(url, {
          headers: {
            Authorization: `Bearer ${Token}`,
            'Content-Type': 'application/json',
          },
        });

        result = await result.json();
        const { data } = result;

        setFormData((prevFormData) => ({
          ...prevFormData,
          
          areaAllocateToProject: data.areaAllocateToProject || '',
          areaAvailableForProject: data.areaAvailableForProject || '',
          size: data.size || '',
         
          
        }));
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchUsers();
  }, [formData.locationId, apiUrl, Token]);

  // location data get
  useEffect(() => {
    const fetchUser = async () => {

      try {
        const url = `${apiUrl}/project/getProject/${empid}`;
        let result = await fetch(url, {
          headers: {
            Authorization: `Bearer ${Token}`,
            'Content-Type': 'application/json',
          },
        });

        result = await result.json();
        const { data } = result;

        const photo = data.banner;
        setProfilePic(photo)
        setFormData((prevFormData) => ({
          ...prevFormData,
          locationId: data.locationId,
          projectName: data.projectName,
          status: data.status,
          banner: data.banner,
          details: data.details,
          noOfPlot: data.noOfPlot,
          noOfShop: data.noOfShop,
          noOfFarmHouse: data.noOfFarmHouse,

          areaAllocateToProject: data.areaAllocateToProject,
          areaAvailableForProject: data.areaAvailableForProject  || '',
          size: data.size || '',
        }));

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchUser();
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  // location update

  const handleUpdate = async (e) => {
    e.preventDefault();

    try {
      const formDataToSend = new FormData();

      for (const key in formData) {
        if (formData[key] !== null) {
          formDataToSend.append(key, formData[key]);
        }
      }

      const response = await fetch(`${apiUrl}/project/updateProject/${empid}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
        body: formDataToSend,
      });

      const response2 = await response.json();

      if (response2.status === "error") {
        throw new Error(response2.message);
      }

      setFormData(initialFormData);
      toast.success(response2.message);
      navigate("/list-projects");
    } catch (error) {
      toast.error(error.message);

    }
  };

  const handleFileChange = (e) => {
    const files = e.target.files;

    if (files.length > 0) {
      const profilePicFile = files[0];

      if (profilePicFile.type.startsWith('image/')) {
        const imageUrl = URL.createObjectURL(profilePicFile);
        setProfilePic(imageUrl);
        setFormData((prevData) => ({
          ...prevData,
          banner: profilePicFile,
        }));

      }
    } else {
      console.log('No file selected');
    }
  };

  //location 
  useEffect(() => {
    fetch(`${apiUrl}/location/locationDropdown`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${Token}`

      }
    })
      .then(response => response.json())
      .then(data => {
        if (data && Array.isArray(data.data)) {
          setLocation(data.data);
        } else {
          console.error('API response does not contain an array:', data);
        }
      })
      .catch(error => {
        console.error('Error fetching gender options:', error);
      });
  }, []);

  //status master
  useEffect(() => {

    fetch(`${apiUrl}/master/getAllMasterData/13`, {
      headers: {
        Authorization: `Bearer ${Token}`
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data && Array.isArray(data.data)) {
          setStatus(data.data);
        } else {
          console.error('API response does not contain an array:', data);
        }
      })
      .catch(error => {
        console.error('Error fetching gender options:', error);
      });
  }, []);

 

  useEffect(() => {
    const token = localStorage.getItem('Token');

    if (!token) {
      navigate('/');
    }
  }, [navigate]);

  return (
    <>

      <div className="page">
        {/* Main Header*/}
       
        <TopHeader />
        <Prince />

        <div className="main-content  pt-0">
          <div className="main-container container-fluid">
            <div className="inner-body">
              {/* Page Header */}
              <div className="page-header">
                <div>
                  <h2 className="main-content-title tx-24 mg-b-5">Update Project</h2>
                </div>
              </div>
              {/* End Page Header */}
              {/* Row */}
              <div className="row row-sm">
                <div className="col-xl-12 col-lg-12 col-md-12">
                  <div className="card custom-card">
                    <div className="card-body">
                      <div>
                        <h6 className="main-content-label mb-3">BASIC INFO</h6>
                      </div>
                      <form action="form-validation.html" data-parsley-validate="">
                        <div className="">
                          <div className="row row-sm">
                            <div className="col-lg-3 form-group">
                              <label className="form-label">
                                Project Name:<span className="tx-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                name="projectName"
                                value={formData.projectName}
                                onChange={handleChange}
                                placeholder="Enter "
                                required=""
                                type="text"
                              />
                            </div>
                            <div className="col-lg-3 form-group">
                              <label className="form-label">
                                Select Land: <span className="tx-danger">*</span>
                              </label>
                              <select className="form-control select2"
                                name="locationId"
                                value={formData.locationId}
                                onChange={handleChange}>
                                <option >Select </option>
                                {location.map((option, index) => (
                                  <option key={option.id} value={option.id}>
                                    {option.locationName}
                                  </option>
                                ))}
                              </select>
                            </div>

                            <div className="col-lg-3 form-group">
                              <label className="form-label">
                                Land Area (Beegha)
                              </label>
                              <input
                                className="form-control"
                                name="size"
                                value={formData.size}
                                // onChange={handleChange}
                                placeholder="Enter "
                                required=""
                                type="text"
                              />
                            </div>
                            <div className="col-lg-3 form-group">
                              <label className="form-label">
                                Available Area (Beegha)
                              </label>
                              <input
                                className="form-control"
                                name="areaAvailableForProject"
                                value={formData.areaAvailableForProject}
                                onChange={handleChange}
                                placeholder="Enter "
                                required=""
                                type="text"
                              />
                            </div>

                            <div className="col-lg-3 form-group">
                              <label className="form-label">
                                Allocate Area (Beegha)
                              </label>
                              <input
                                className="form-control"
                                name="areaAllocateToProject"
                                value={formData.areaAllocateToProject}
                                onChange={handleChange}
                                placeholder="Enter "
                                required=""
                                type="text"
                              />
                            </div>


                            <div className="col-lg-3 form-group">
                              <label className="form-label">
                                Display Status: <span className="tx-danger">*</span>
                              </label>
                              <select className="form-control select2"
                                name="status"
                                value={formData.status}
                                onChange={handleChange}>
                                <option >Select </option>
                                {status.map((option, index) => (
                                  <option key={option.id} value={option.name}>
                                    {option.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="col-lg-3 form-group">
                              <label className="form-label">
                                No Of Plot:
                                <span className="tx-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                name="noOfPlot"
                                value={formData.noOfPlot}
                                onChange={handleChange}
                                placeholder="Enter "
                                required=""
                                type="text"
                              />
                            </div>
                            <div className="col-lg-3 form-group">
                              <label className="form-label">
                                No Of Shop:
                                <span className="tx-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                name="noOfShop"
                                value={formData.noOfShop}
                                onChange={handleChange}
                                placeholder="Enter "
                                required=""
                                type="text"
                              />
                            </div>
                            <div className="col-lg-3 form-group">
                              <label className="form-label">
                                No Of Farm House:
                                <span className="tx-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                name="noOfFarmHouse"
                                value={formData.noOfFarmHouse}
                                onChange={handleChange}
                                placeholder="Enter "
                                required=""
                                type="text"
                              />
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              {/* Row */}
              <div className="row row-sm">
                <div className="col-lg-8 col-md-8">
                  <div className="card custom-card">
                    <div className="card-body">
                      <div>
                        <h6 className="main-content-label mb-1">Enter Details: <span className="tx-danger">*</span></h6>
                      </div>
                      <div className="row row-sm">
                        <div className="col-sm-12 mg-t-10">
                          <textarea
                            className="form-control"
                            style={{ height: 200 }}
                            defaultValue={""}
                            name="details"
                            value={formData.details}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4">
                  <div className="card custom-card">
                    <div className="card-body">
                      <div>
                        <h6 className="main-content-label mb-1">Project Banners: <span className="tx-danger">*</span></h6>
                      </div>
                      <form action="form-validation.html" data-parsley-validate="">
                        <div className="">
                          <div className="row row-sm">
                            <div className="col-sm-12 col-md-12">
                              <input
                                type="file"
                                className="dropify"
                                data-default-file="../assets/img/media/1.jpg"
                                data-height={200}
                                name="banner"
                                onChange={handleFileChange}
                              />
                              <div style={{ width: '350px', height: '180px', border: '1px solid #ccc', marginTop: '8px', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0 auto', maxWidth: '100%' }}>
                                {profilePic && (
                                  <img src={profilePic} alt="Selected File" style={{ width: "100%", height: "100%" }} />
                                )}
                                {!profilePic && (
                                  <p style={{ textAlign: 'center', margin: 0 }}>No photo selected</p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Row */}
              <div className="row row-sm">
                <div className="col-12 mb-3">
                  <a
                    href=""
                    className="btn btn-primary"
                    type="submit"
                    onClick={handleUpdate}
                  >
                    Update
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="main-footer text-center">
          <div className="container">
            <div className="row row-sm">
              <div className="col-md-12">
                <span>
                  Copyright © 2024 <a href="javascript:void(0)">AMRS</a>. Designed
                  by <a href="http://webkype.com/">Webkype.com</a> All rights
                  reserved.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>

  )
}

export default ProjectEdit