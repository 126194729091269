import React, { useState, useEffect } from "react";
import TopHeader from '../Components/TopHeader';
import Prince from '../Components/Prince';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useParams, useNavigate } from 'react-router-dom';


const AddCompany = () => {

    const initialFormData = {
        profilePhoto: '',
        companyName: '',
        companyEmail: '',
        companyPhone: '',
        companyWebsite: '',
        contactName: '',
        contactEmail: '',
        contactMobile: '',
        GST: '',
        alternateMobileNo: '',

    };

    const [formData, setFormData] = useState(initialFormData);
    const [profilePicFile, setProfilePicFile] = useState(null);
    const navigate = useNavigate();
    const apiUrl = process.env.REACT_APP_URL;
    const Token = localStorage.getItem("Token");



    const handleFileChange = (e) => {
        const files = e.target.files;

        if (files.length > 0) {
            const profilePicFile = files[0];

            if (profilePicFile.type.startsWith("image/")) {
                setProfilePicFile(profilePicFile);
                setFormData((prevData) => ({
                    ...prevData,
                    profilePhoto: profilePicFile,
                }));

            }
        } else {
            console.log("No file selected");
        }
    };


    // from submit company

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const formDataToSend = new FormData();

            for (const key in formData) {
                if (formData[key] !== null) {
                    formDataToSend.append(key, formData[key]);
                }
            }

            const response = await fetch(`${apiUrl}/company/addCompany`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${Token}`,
                },
                body: formDataToSend,
            });

            const response2 = await response.json();

            if (response2.status === "error") {
                throw new Error(response2.message);
            }

            setFormData(initialFormData); 
            toast.success(response2.message);
            navigate("/company-list");
        } catch (error) {
            toast.error(error.message);
            
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    }


    useEffect(() => {
        const token = localStorage.getItem('Token');
    
        if (!token) {
            navigate('/');
        }
      }, [navigate]);

    return (
        <>
            
            <div className="page">
           

                <TopHeader />
                <Prince />
                {/* Main Content*/}
                <div className="main-content pt-0" >
                    <div className="main-container container-fluid">
                        <div className="inner-body">
                            {/* Page Header */}
                            <div className="page-header">
                                <div>
                                    <h2 className="main-content-title tx-24 mg-b-5">Add Company</h2>
                                </div>
                            </div>
                            {/* End Page Header */}
                            {/* Row */}
                            <div className="row row-sm">
                                <div className="col-xl-4 col-lg-4 col-md-4">
                                    <div className="card custom-card">
                                        <div className="card-body">
                                            <div>
                                                <h6 className="main-content-label mb-4">COMPANY LOGO</h6>
                                            </div>
                                            <form action="form-validation.html" data-parsley-validate="">
                                                <div className="">
                                                    <div className="row row-sm">
                                                        <div className="col-sm-12 col-md-12">
                                                            <input
                                                                type="file"
                                                                className="dropify"

                                                                data-height={200}
                                                                name="profilePhoto"
                                                                onChange={handleFileChange}
                                                            />
                                                            <div style={{ width: '350px', height: '220px', border: '1px solid #ccc', marginTop: '8px', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0 auto', maxWidth: '100%' }}>
                                                                {profilePicFile && (
                                                                    <img
                                                                        src={URL.createObjectURL(profilePicFile)}
                                                                        alt="Selected File"
                                                                        style={{ width: "100%", height: "100%" }}
                                                                    />
                                                                )}
                                                                {!profilePicFile && (
                                                                    <p style={{ textAlign: 'center', margin: 0 }}>No photo selected</p>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-8 col-lg-8 col-md-8">
                                    <div className="card custom-card">
                                        <div className="card-body">
                                            <div>
                                                <h6 className="main-content-label mb-3">BASIC INFO</h6>
                                            </div>
                                            <form action="form-validation.html" data-parsley-validate="">
                                                <div className="">
                                                    <div className="row row-sm">
                                                        <div className="col-lg-6 form-group">
                                                            <label className="form-label">
                                                                Company Name: <span className="tx-danger">*</span>
                                                            </label>
                                                            <input
                                                                className="form-control"

                                                                placeholder="Enter"
                                                                required=""
                                                                type="text"
                                                                name="companyName"
                                                                value={formData.companyName}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                        <div className="col-lg-6 form-group">
                                                            <label className="form-label">
                                                                Company Email: <span className="tx-danger">*</span>
                                                            </label>
                                                            <input
                                                                className="form-control"

                                                                placeholder="Enter "
                                                                required=""
                                                                type="text"
                                                                name="companyEmail"
                                                                value={formData.companyEmail}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                        <div className="col-lg-6 form-group">
                                                            <label className="form-label">
                                                                Company Phone: <span className="tx-danger">*</span>
                                                            </label>
                                                            <input
                                                                className="form-control"

                                                                placeholder="Enter"
                                                                required=""
                                                                type="text"
                                                                name="companyPhone"
                                                                value={formData.companyPhone}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                        <div className="col-lg-6 form-group">
                                                            <label className="form-label">
                                                                Company Website:{" "}
                                                                <span className="tx-danger">*</span>
                                                            </label>
                                                            <input
                                                                className="form-control"

                                                                placeholder="Enter"
                                                                required=""
                                                                type="text"
                                                                name="companyWebsite"
                                                                value={formData.companyWebsite}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                        <div className="col-lg-6 form-group">
                                                            <label className="form-label">
                                                                Contact Name: <span className="tx-danger">*</span>
                                                            </label>
                                                            <input
                                                                className="form-control"

                                                                placeholder="Enter"
                                                                required=""
                                                                type="text"
                                                                name="contactName"
                                                                value={formData.contactName}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                        <div className="col-lg-6 form-group">
                                                            <label className="form-label">
                                                                Contact Email: <span className="tx-danger">*</span>
                                                            </label>
                                                            <input
                                                                className="form-control"

                                                                placeholder="Enter"
                                                                required=""
                                                                type="text"
                                                                name="contactEmail"
                                                                value={formData.contactEmail}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                        <div className="col-lg-6 form-group">
                                                            <label className="form-label">
                                                                Contact Mobile: <span className="tx-danger">*</span>
                                                            </label>
                                                            <input
                                                                className="form-control"

                                                                placeholder="Enter"
                                                                required=""
                                                                type="text"
                                                                name="contactMobile"
                                                                value={formData.contactMobile}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                        <div className="col-lg-6 form-group">
                                                            <label className="form-label">
                                                                Alternate Contact Mobile:
                                                            </label>
                                                            <input
                                                                className="form-control"

                                                                placeholder="Enter"
                                                                required=""
                                                                type="text"
                                                                name="alternateMobileNo"
                                                                value={formData.alternateMobileNo}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                        <div className="col-lg-6 form-group">
                                                            <label className="form-label">
                                                                GST
                                                            </label>
                                                            <input
                                                                className="form-control"

                                                                placeholder="Enter"
                                                                required=""
                                                                type="text"
                                                                name="GST"
                                                                value={formData.GST}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End Row */}
                            <div className="row row-sm">
                                <div className="col-12 mb-3">
                                    <a
                                        href="employee-profile.html"
                                        className="btn btn-primary"
                                        type="submit"
                                        onClick={handleSubmit}
                                    >
                                        Submit
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
                {/* End Main Content*/}
                {/* Main Footer*/}
                <div className="main-footer text-center">
                    <div className="container">
                        <div className="row row-sm">
                            <div className="col-md-12">
                                <span>
                                    Copyright © 2024 <a href="javascript:void(0)">AMRS</a>. Designed
                                    by <a href="http://webkype.com/">Webkype.com</a> All rights
                                    reserved.
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
               
            </div>

        </>

    )
}

export default AddCompany
